.App .service-info {
  @media screen and (max-width: 1066px) {
    .top-panel {
      display: flex;
    }
  }

  @media screen and (min-width: 1200px) {
    .top-panel {
      padding: 0 13%;
    }
  }

  .content {
    font-size: 14px;
    color: #555;
    padding-top: 4rem;

    @media screen and (min-width: 1067px) {
      padding-top: 5rem !important;
      margin-top: 0px !important;
    }

    @media screen and (max-width: 1066px) {
      padding-top: 5rem !important;
      margin-top: 0px !important;
    }

    table {
      td,th {
        padding: 5px 10px;
        border: 1px solid black;
      }
    }
  }
}
