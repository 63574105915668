.add-web {
  form {
    .form-group {
      .checkbox-wrapper {
        display: flex;
        align-items: center;

        label {
          margin: 0 10px;
        };
      }
    }

    .advanced-options-button {
      button {
        display: flex;
        align-items: center;        
        padding: 0; 

        svg {
          margin-left: 10px;
        }
      }
    }
  }

  #c-panel-modal {
    padding: 2rem;

    form .form-group input[type="text"] {
      width: 90% !important;
    }

    label {
      color: white;
    }

    .form-group {
      padding-left: 1.5rem;

      textarea {
        width: 90% !important;
      }
    }

    .l-col {
      display: none;
    }
  }
}