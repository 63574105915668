.packages {
   .r-col {
    .stats {
      .c-2 {
        padding-left: 2rem;

        div > span {
          width: 50%;
        }
      }

      .c-3 {
        margin-left: 0;

        div.ns {
          margin-top: 5px;
        }
      }
    }
  }
}