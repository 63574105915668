.edit-item form {
  div.name-server-input-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0;

  button {
    margin-left: 10px;
  }
}

.exp-date .optional {
  font-size: 12px;
  padding: 0 0 0 6px;
  font-weight: normal;
}
}