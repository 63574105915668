.edit-firewall {
  form {
    .form-group {
      .label-wrapper {
        span {
          text-transform: initial;
        }
      }
    }
  }
}