@import 'src/utils/scss/variables';

.mail-info-block {
  .select-group {
    select {
      margin-bottom: 10px;
      margin-top: 5px;
      padding: 0;
      border: none;
      color: $primary;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: bold;
      cursor: pointer;
    }

    .details {
      font-size: 14px;
      color: #777;

      > div {
        display: flex;

        span:nth-child(1) {
          width: 50%;
        }
      }
    }
  }
}