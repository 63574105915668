span.dns-records {
  padding-left: 25px;
  font-size: 18px;
  font-weight: normal;
  font-style: italic;
  color: gray;
}

.r-col {
  .stats {
    .c-2.w-30 {
      padding-left: 0;
    }
  }
}