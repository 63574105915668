.statistic-item {
  display: flex;
  justify-content: center;
  padding: 25px 0;
  border-bottom: 1px solid #eee;

  .l-col {
    .date {
      font-size: 13px;
      margin-top: 3rem;
    }
  }

  .r-col {
    .name {
      font-size: 32px;
    }

    .c-1 {
      .bandwidth, .disk {
        width: 220px;
      }

      div.sub-disk-stats {
        width: 250px;
      }
    }

    .c-2 {
      margin-right: 0;

      div>span:nth-child(2) {
        margin: 0;
        padding: 0;
      }
    }

    .c-3.w-35 {
      span.stat {
        width: 80px;
      }

      
      div > span {
        width: 50%;
        text-align: left;
      }

      div>span:nth-child(2) {
        width: 100px;
      }
    }
  }
}