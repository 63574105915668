.l-col {
  padding-left: 15px;
}

.r-col {
  font-size: 13px;
}

.w-14 {
  width: 14.3%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-85 {
  width: 85%;
}