@import 'src/utils/scss/variables';

$optionalButtonHover: $primary;
$optionalButtonActive: $primaryLight;
$deleteButtonColorHover: #b00e5b;
$deleteButtonColorActive: #b11661;
$backButtonBackground: #DFDEDD;
$backButtonBackgroundHover: #999;
$transition: all 200ms cubic-bezier(0.4, 0.1, 0.5, 0.85);
$errorColor: #BE5ABF;

.content .edit-template {
  padding-bottom: 2rem;

  .toolbar {
    justify-content: unset;
  
    > div {
      width: 14.3%;
      display: flex;
      align-items: center;
    }
  
    div.error,
    div.success {
      width: fit-content !important;
  
      span {
        font-weight: bold;
        font-size: 14px;
  
        svg {
          font-size: 13px;
          margin-right: 10px;
        }
      }
  
      span.error-message {
        color: $errorColor;
  
        svg {
          margin-right: 7px;
          font-size: 13px;
          color: $errorColor;
        }
      }
  
      span.ok-message {
        color: $primary;
  
        svg {
          color: $primary;
        }
      }
    }
  
    .search-toolbar-name {
      width: auto !important;
      padding: 10px 0;
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.hide {
  display: none;
}

.show {
  display: flex;
}

.edit-item,
.login-layout {
  display: flex;
  font-size: 15px;
  margin-top: 3rem;
  margin-bottom: 1rem;

  .l-col {
    margin-right: 1.75%;
  }

  form {
    button,
    a {
      color:$primary;
      font-weight: bold;
    
      &:hover {
        color: $secondaryLight;
      }
    
      &:active {
        color: $secondaryActive;
      }
    }

    button.optional {
      &:hover {
        color: $optionalButtonHover;
      }

      &:active {
        color: $optionalButtonActive;
      }
    }

    button.delete {
      &:hover {
        color: $deleteButtonColorHover;
      }

      &:active {
        color: $deleteButtonColorActive;
      }
    }

    .form-group {
      input[type=text],
      input[type=password],
      input[type=email],
      textarea,
      select {
        width: 50%;
      }
    }

    @media screen and (max-width: 900px) {
      .form-group {
        input[type=text],
        input[type=password],
        input[type=email],
        textarea,
        select {
          width: 100%;
        }
      }
    }

    width: 100%;

    .form-group select,
    .form-group textarea {
      border-radius: 0;

      &:hover {
        border-color: #909090;
      }

      &:focus,
      &:active {
        outline: none;
        border-color: $primaryActive;
        box-shadow: unset;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
      background: $primaryLight;
      border-color: $primaryActive;
      filter: none;
      box-shadow: none;
    }

    input:autofill,
    input:autofill:hover,
    input:autofill:focus,
    input:autofill:active  {
      background: $primaryLight;
      border-color: $primaryActive;
      filter: none;
      box-shadow: none;
    }

    button {
      background: unset;
      border: none;
      box-shadow: unset;
    
      &:focus,
      &:active {
        outline: none;
      }
    }
    
    input {
      height: calc(1.5em + 0.75rem + 6px);
      border-radius: 0;

      &:hover {
        border-color: #909090;
      }

      &:focus,
      &:active {
        box-shadow: unset;
        border-color: $primaryActive;
        background: #d7dcef9e;
      }
    }
    
    label {
      color: $textColor;
      font-weight: bold;
    }
    
    label.label-wrapper {
      display: flex;
      align-items: flex-end;
      width: fit-content;

      span {
        font-weight: normal;
        text-transform: uppercase;
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;

      label {
        margin: 0 12px;
      }
    }

    input {
      color: $textColor;
    }

    .buttons-wrapper {
      margin-top: 2.5rem;

      button {
        font-weight: bold;
        font-size: 13px;
        margin-right: 10px;
        padding: .35rem 2.25rem;
        border-radius: 4px;
        transition: $transition;
      }

      .add {
        color: $activeButtonText;
        background: $primary;

        &:hover {
          color: $hoverButtonText;
          background: $primaryLight;
        }
        
        &:active {
          color: $activeButtonText;
          background: $primaryActive;
        }
      }

      .back {
        color: #777;
        background: $backButtonBackground;

        &:hover {
          color: white;
          background: $backButtonBackgroundHover;
        }
      }
    }
  }

  .l-col {
    padding: 20px 0 0;

    .date,
    .time {
      margin: 10px 0 0;
      color: #777;
      font-size: 12px;
      letter-spacing: 1px;
    }

    .time {
      margin: 6px 0 10px;
    }

    .status {
      color: $primary;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 1066px) {
  .form-group {
    input[type=text],
    input[type=password],
    input[type=email],
    textarea,
    select {
      width: 75%;
    }
  }
}
