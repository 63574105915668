.select-wrapper {
  display: flex;

  select {
    width: auto;
  }

  select, button {
    border-radius: 0;
  }
}