.cron-jobs-list.r-col .name {
  font-size: 25px;
}

.cron-col > div {
  display: flex;
  flex-direction: column;

  span {
    font-size: 18px;
    font-weight: bolder;
  }
}