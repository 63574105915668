.packages-wrapper {
  .r-col .stats {
    .c-1 .stat {
      margin-left: 1rem;
    }

    .c-2 {
      padding: 0;
    }
  }
}