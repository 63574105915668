.carousel {
  width: 100%;
  position: relative;

  a {
    width: 5%;
  }

  .carousel-inner {
    .carousel-item {
      background: #000;
      width: 100%;
      height: 100vh;
        
        .gif {
          height: 50%;
          width: 50%;
        }

        .img {
          width: 90%;
        }
    }
  }

  .carousel-indicators {
    .indicator {
    margin: 0 15px;
    width: 80px;
    height: 80px;

      .control-photo {
        width: 100px;
        height: 80px;
      }
      
      &:hover {
        cursor: pointer;
      }

      &.active > img {
        border: 3px solid #3cc3f0;
      }

      .active {
        border: 3px solid #3cc3f0;
      }
    }
  }
}

span.close {
  z-index: 10;
  font-size: 30px;
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 1;
  color: white;
  cursor: pointer;

  &:hover {
    color: white;
  }
}