.statistic-item {
  display: flex;
  flex: 1 1 auto;
  padding: 25px 0;
  border-bottom: 1px solid #eee;

  .l-col {
    padding-left: 0;
    flex-basis: 14.3%;
  }

  .r-col {
    flex-basis: 85.7%;

    .stats {
      align-items: center;

      .name {
        font-size: 24px;
        color: #111;
      }

      .exclusion-items {
        flex-basis: 83.4%;
      }

      > div {
        flex: unset;
        flex-basis: 16.6%;
      }
    }
  }
}
