$secondary: #fcac04;

.hotkeys-list {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  transform: translateX(-45%);
  width: 53%;
  background: #222e44de;
  font-size: 13px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $secondary;

    .name {
      text-transform: uppercase;
      padding: 5px 0 5px 10px;
      font-size: 12px;
      font-weight: bold;
      color: $secondary;
      letter-spacing: 2px;
      padding: 15px;
    }

    .close {
      padding: 12px;
      opacity: 1 !important;

      svg {
        color: $secondary;
      }

      &:hover {
        cursor: pointer;
        background: #222e44;
      }
    }
  }

  .body {
    display: flex;

    ul {
      padding: 25px 10px;
      margin: 0;
      width: 50%;
      list-style: none;
      margin-left: 3rem;

      li {
        padding: 5px;

        span.name {
          margin-right: 15px;
          color: $secondary;
          font-weight: bold;
        }
    
        span.description {
          color: white;
        }
      }

      li.space-top {
        padding-top: 30px;
      }
    }
  }
}

.hide {
  display: none;
}