@import 'src/utils/scss/variables';

.content {
  padding: 0 13%;
  padding-bottom: 1%;
  margin-top: 5px;

  > div > div.total {
    margin-top: 25px;
    margin-left: 14%;
    font-size: 12px;
    color: #929292;
  }
}

@media (max-width: 1350px) {
  .content {
    padding: 0 10%;
  }
}

@media (max-width: 1066px) {
  .content {
    padding: 5% 10%;
  }
}

@media (max-width: 900px) {
  .content {
    margin-top: 40px;
  }
}

@media (max-width: 800px) {
  .content {
    padding-top: 5%;
  }
}

.fixed-buttons {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > div {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 5px;
    border-radius: 50%;
    background: #c3c3c3;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &:hover {
      color: $hoverButtonText;
      background: $primaryLight;
    }
  
    &:active {
      color: $activeButtonText;
      background: $primaryActive;
    }
  
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(1.32);
  
      svg {
        color: white;
        height: 70%;
      }
    }
  }

  > div:first-child {
    color: #c3c3c3;
    background: none;

    &:hover {
      background: $primaryLight;

      button svg {
        color: $activeButtonText;
      }
    }

    &:active {
      color: $activeButtonText;
      background: $primaryActive;
    }

    button {
      padding: 0;

      svg {
        color: #c3c3c3;
      }
    }
  }
}

@media screen and (max-width: 1066px) {
  .fixed-buttons > div:first-child {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .content {
    padding: 5% 1%;
  }
}
