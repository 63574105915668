@import 'src/utils/scss/variables';

.menu-wrapper {
  position: fixed;
  width: 100%;
  font-size: 15px;
  background: white;

  .menu-stat {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    min-height: 45px;
    max-height: 135px;
    height: 145px;
    width: 100%;
    padding: 0 13%;
    
    > div {
      border-bottom: 3px solid white;
      overflow: hidden;
      width: 14.3%;

      h3, div {
        width: 100%;
      }

      h3 {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      
      div {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #a0a0a0;
        text-align: left;

        > span:nth-child(1) {
          width: 120px;
        }

        > span:nth-child(2) {
          width: 80px;
          padding-left: 10px;
        }
      }
    }

    .stat {
      a, button {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: black;
        text-decoration: none;
        text-align: left;
      }

      button {
        background: none;
        border: none;
        padding: 0;
      }

      h3 {
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
        border-bottom: 3px solid $secondaryLight;
      
        h3 {
          color: $secondary;
        }

        .stats {
          overflow: hidden;
        }
      }

      &:active {
        border-color: $secondaryActive;

        h3 {
          color: $secondary;
        }
      }

      .stats {
        display: flex;
        flex-direction: column;
        padding-left: 0;
      }
    }

    .l-active {
      border-bottom: 3px solid $primary;

      h3 {
        color: $primary;
        font-size: 18px;
        margin-bottom: 25px;
      }
    }

    .stat.focus {
      border-bottom: 3px solid $secondaryLight !important;
      
      a, h3 {
        color: $secondaryLight !important;
      }
    }
  }
}

.menu-wrapper.shadow {
  box-shadow: rgba(200, 200, 200, 0.5);
}

@media (max-width: 1350px) {
  .menu-wrapper .menu-stat {
    padding: 0 10%;
  }
}

@media screen and (max-width: 1200px) {
  .menu-wrapper {
    padding: 0 10%;

    .menu-stat {
      padding: 0;
    }
  }
}

@media screen and (max-width: 1066px) {
  .menu-wrapper {
    .menu-stat {
      padding: 0 10%;
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .menu-wrapper {
    .menu-stat {
      padding: 0 10%;
    }
  }
}

@media (max-width: 1066px) {
  .menu-wrapper {
    padding: 1%;

    .menu-stat {
      height: auto;
      min-height: auto;

      .stat {
        h3 {
          padding: 0;
        }
      }
    }
  }
}
