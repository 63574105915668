.firewalls .list-item {
  .l-col .date {
    display: none;
  }

  .r-col .stats {
    margin-top: 15px;

    .cron-col {
      div span {
        font-weight: unset;
        font-size: 13px;
      }

      div span.stat {
        font-weight: bold;
      }
    }
  }
}