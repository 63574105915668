.search-input-form {
  display: flex;
  width: 180px;

  input {
    height: 30px;
    margin-left: 10px;
    border-radius: 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: 1px groove #ffffff;
    height: 30px;
  }
}