.progress.upload {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  height: 5px;
  position: fixed;
  width: 100%;
  margin: 0;
  display: inline-table;
  background: transparent;

  .progress-bar {
    height: 5px;
  }
}
