@import 'src/utils/scss/variables';
@import 'src/utils/scss/breakpoints';

.mobile-top-nav-wrapper.hide {
  opacity: 0;
}

.mobile-top-nav-wrapper.show {
  z-index: 5;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 111px;
  background: white;
  display: flex;
  flex-direction: column;
  animation: showMobileNav forwards .3s;
  margin-top: 82px;

  .mobile-menu {
    padding: 0 10%;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  > div .top-link {
    display: flex;
    font-size: 14px;
    padding: 4px 0;

    &:hover {
      background: $secondaryLight;
      
      a, button {
        color: $white;
      }
    }
  }

  div {
    display: flex;
    
    a, button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px !important;
      width: fit-content;
      height: 100%;
      text-decoration: none;
      color: $black;
    }

    button {
      background: none;
      border: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  div.active {
    background: white;
    
    a, button {
      color: $secondary;
      font-weight: bold;

      &:hover {
        color: white;
      }

      &:active {
        background: $secondaryActive;
        color: white;
      }
    }

    &:hover {
      color: white;
      background: $secondaryLight;
    }
  }

  .toolbar {
    margin: 0;
    position: relative;
    border: none;
  }


  .mobile-stat-menu {
    box-shadow: 0 5px 3px 0 hsla(0,0%,78.4%,.5);

    .menu-wrapper {
      position: relative;
      height: auto;
      min-height: auto;
      padding: 0;
      margin-top: 9px;

      .menu-stat {
        margin: 0;

        .stat {
          text-align: left;
          flex: 1 1 auto;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media (max-width: 1066px) {
  .mobile-stat-menu .menu-wrapper .menu-stat {
    margin-top: 40px;

    .stat {
      .stats {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .mobile-toolbar .toolbar {
    padding: 0 1%;
  }
}

@media (max-width: 725px) {
  .mobile-top-nav-wrapper.show {
    .menu-stat {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .mobile-menu {
    padding: 0 10%;
  }
}

@keyframes showMobileNav {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
