.servers-list {
  .servers-wrapper .list-item.sys-info {
    .r-col {
      padding: 0;

      .name {
        font-size: 35px;
      }

      .c-2 span > span.stat {
        margin-left: 10px;
      }

      .c-3 {
        padding-right: 0;

        > div > span {
          width: 100%;
        }
      }
    }
  }

  .actions a.link-gray.restart svg,
  .actions button.link-gray.restart svg {
    width: 1rem;
    height: 1rem;
  }
}