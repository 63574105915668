.edit-package {
  form {
    .form-group {
      .input-wrapper {
        display: flex;
        align-items: center;

        button {
          transform: translateX(5px);
        }
      }
    }
  }
}