.cron-form-body {
  .third-tab-selects,
  .fourth-tab-selects {
    display: flex; 

    div.hour {
      select {
        width: 70px;
        margin-left: 4.8rem;
      }
    }

    div.minute {
      label {
        margin: 0;
      }

      select {
        margin: 0;
      }
    }
  }

  .fifth-tab-selects {
    display: flex;

    > div {
      width: 10%;

      select {
        width: 70px;
      }
    }

    > div.date {
      margin: 0 0 1rem 0;
    }

    > div.minute {
      width: 200px;
    }

    > div.hour {
      width: 155px;
      margin-left: 1rem;

      select {
        margin-left: .75rem;
      }
    }

    > div.date {
      width: 240px;

      select {
        margin-left: 5.85rem;
      }
    }
  }
}