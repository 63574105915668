.editor {
  width: 100%;

  .panel-editor {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 40px;
    position: absolute;
    padding: 3px;
    margin: 0;
    background: #000000bf;
    z-index: 4;
    transition: 0.5s all ease-in-out;
    border-bottom: 1px solid black;
    border-radius: 0;

      button {
        font-size: 17px;
        height: 98%;
        margin: 0 0 0 30px;
        padding: 0 10px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
  }

  .close {
    z-index: 4;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 40px;
    opacity: 1;
    color: #000;

    &:hover {
      color: rgb(139, 139, 139);
    }
  }

  .CodeMirror {
    height: 100vh;
    padding-top: 4vh;

    .CodeMirror-selected {
      background:#ACCEF7;
    }

    .CodeMirror-scroll {
      font-size: 14px;
    }
  }

  .save {
    font-size: 20px;
    left: 95%;
    bottom: 10px;
    position: absolute;
  }
}