.video-preview {
  padding-bottom: 17px;
  width: 100%;
  background: #000;
  
  .close {
    z-index: 1;
    font-size: 30px;
    position: absolute;
    top: 5px;
    right: 2px;
    opacity: 1;
    color: #fff;

    &:hover {
      color: #000;
    }
  }

  .video {
    width: 100%;
    height: auto;

    &:focus {
      outline: none;
    }
  }
}