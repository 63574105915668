.logs-list {
  .toolbar {
    padding: 6px 13%;
  }

  .statistic-item {
    .l-col {
      font-size: 13px;
      color: #888;
  
      .date {
        margin: 1rem 0;
        margin-bottom: 1.5rem;
      }
    }
  
    .r-col {
      .name {
        margin-top: .7rem;
        font-size: 18px;
      }
    }
  }

  .statistic-item.focused {
    border-left: 2px solid #5edad0;

    div.name {
      color: #5edad0;
    }
  }
}

@media (max-width: 1350px) {
  .logs-list .toolbar {
    padding: 6px 9.5%;
  }
}