@import 'src/utils/scss/variables';

.edit-web {
  .ssl-support {
    .additional-info {
      display: flex;
      flex-direction: column;

      span {
        &:nth-child(1) {
          width: 120px;
          display: inline-block;
        }

        &:nth-child(2) {
          width: fit-content;
        }
      }
    }

    a.generate-csr {
      text-transform: initial;
      text-decoration: none;
      color: $primary;
      font-weight: bold;

      &:hover {
        color: $secondaryLight;
      }
    }
  }
}