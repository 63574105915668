.timer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    background: none;

    &:active, &:focus {
      outline: none;
    }
  }

  .circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    span.seconds {
      &:hover, &:active {
        background: unset;
      }
    }

    svg {
      height: 30px;
      width: 30px;

      circle {
        stroke: black;
        fill: white;
        stroke-width: 3%;
        stroke-dasharray: 72;
        stroke-dashoffset: 9;
        transform-box: fill-box;
        transform-origin: center;
        transform: rotate(270deg);
        transition: all 1s linear;
      }
    }
  }
}