$secondary: #fcac04;

.content {
  .toolbar {
    .search-toolbar-name {
      font-size: 12px;
      text-transform: uppercase;
      color: $secondary;
      font-weight: bold;
      margin-left: 14.3%;
      padding-left: 7px;
      align-self: center;
    }

    .search-toolbar-right {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-group {
        button {
          border: none;
          background: none;
          box-shadow: none;

          &:focus, &:active, &:hover {
            background: none;
            box-shadow: none;
            outline: none;
            border: none;
            color: #686868;
          }
        }
      }

      .search-input-form {
        padding-top: 2px;
      }
    }
  }

  .statistics-wrapper {
    > .list-item:first-child {
      margin-top: 0;
    }

    .list-item {
      .l-col {
        > .star {
          display: none;
        }
      }

      .r-col {
        div.object {
          text-transform: uppercase;
        }

        .c-2 .owner span,
        .c-3 .status span {
          text-align: center;
          font-weight: bold;
          text-transform: lowercase;
        }
      }
    }
  }
}