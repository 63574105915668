.edit-template.add-cron {
  form {
    .form-group {
      width: 100%;

      input {
        width: inherit;
      }
    }

    .form-group.command {
      width: 100%;
    }

    .cron-form-body {
      display: flex;

      .body-col-1 {
        width: 30%;
      }

      .body-col-2 {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1.7rem 1rem;
        width: 70%;
      }
    }
  }
}