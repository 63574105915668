@import 'src/utils/scss/variables';
@import 'src/utils/scss/breakpoints';

.top-panel.small-device {
  display: none;
}

.top-panel {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  position: fixed;
  width: 100%;
  text-align: center;
  color: white;
  background: #222e44;
  height: 34px;
  align-items: center;
  justify-content: space-between;
  padding: 0 13%;
  z-index: 2;

  .container {
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 100%;

    .logo div {
      img {
        width: 82%;
      }

      &:hover {
        background-color: transparent;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      
      a, button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px !important;
        width: fit-content;
        height: 100%;
        text-decoration: none;
        color: white;
      }

      button {
        background: none;
        border: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    div.active {
      background: white;
      
      a, button {
        color: $secondary;
        font-weight: bold;

        &:hover {
          color: white;
        }

        &:active {
          background: $secondaryActive;
          color: white;
        }
      }

      &:hover {
        background: $secondaryLight;
      }
    }
  }

  .left-menu {
    width: 75%;
    margin: 0;
    padding: 0;
    justify-content: space-between;

    div.top-link,
    div.nav-link {
      flex: 1 1 auto;
      height: 100%;
      transform: translateX(-5px);

      &:hover {
        background: $secondaryLight;
      }
    }

    div.logo {
      width: 7rem;
      padding-left: 0;

      a {
        div {
          padding: 0;
          width: 6rem;
          flex: none;

          img {
            width: 90%;
          }
        }
      }

      &:hover {
        background: transparent;
      }
    }

    div.fm {
      width: 10rem;
    }

    .top-link.focus {
      a, button {
        color: $secondaryActive;
      }
    }
  }

  .profile-menu {
    width: 25%;
    margin: 0;
    padding: 0;
    justify-content: flex-end;

    div {
      height: 100%;
    }

    div.bell {
      width: auto;
      color: $secondary;
      padding: 3px 0;

      svg {
        border-radius: 30px;
        width: 40px;
        height: 30px;
        padding: 3px;

        &:hover {
          background: #79522294;
        }

        &:active {
          background: #866032;
        }
      }
    }

    .edit-user a {
      color: #a4abad;
      font-weight: 700;
    
      &:hover {
        color: $secondaryLight;
      }

      &:active {
        color: $secondaryActive;
      }
    }

    .logout-button a,
    .logout-button button {
      color: white;
      cursor: pointer;
      font-weight: 100;

      &:hover {
        color: $secondaryLight;
      }

      &:active {
        color: $secondaryActive;
      }
    }
  }
}

.top-panel.long-profile {
  .left-menu {
    justify-content: start;

    > div {
      width: fit-content;
      flex: unset;
      padding: 0 1rem;
    }

    .logo {
      width: 7rem;
      padding: 0;
      margin-right: 1rem;
    }
  }

  .profile-menu {
    align-items: center;

    > .edit-user {
      width: fit-content;
    }

    .long-username {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;

      > span:nth-child(1) {
        margin-right: 5px;
      }

      > span:nth-child(3) {
        margin-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .top-panel {
    padding: 0 10%;
  }
}

@media screen and (max-width: $desktopMin) {
  .top-panel {
    padding: 0 8%;
  }
}

@media (max-width: 900px) {
  .top-panel {
    display: none;
  }

  .top-panel.small-device {
    display: flex;
    justify-content: space-between;
    padding: 0 10%;

    > .container {
      align-items: center;
      width: auto;
    }

    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .bar {
        height: 2px;
        margin: 2px;
        width: 20px;
        background: white;
      }
    }
  }
}

@media (max-width: $phoneMax) {
  .top-panel.small-device {
    padding: 0;
  }

  .top-panel .left-menu div.logo {
    width: unset;

    a div {
      width: 5rem;
    }
  }
}
