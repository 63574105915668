@import 'src/utils/scss/variables';

.l-menu {
  transform: translate(35px, 50%);

  a, button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary;
    border-radius: 100%;
    color: white;
    width: 45px;
    height: 43px;
    outline: none;
    border: none;
    text-decoration: none;

    &:hover {
      background: $primaryLight;

      span.add {
        display: block;
        color: $hoverButtonText;
        background: $primaryLight;
      }

      svg {
        color: $hoverButtonText;
      }
    }

    @media screen and (max-width: 900px) {
      span.add {
        display: none !important;
      }
    }

    &:active {
      background: $primary;

      span.add {
        display: block;
        color: $activeButtonText;
        background: $primary;
      }

      svg {
        color: $activeButtonText;
      }
    }

    span.add {
      width: max-content;
      display: none;
      padding: 4px 10px 4px 25px;
      background: $primary;
      border-radius: 15px;
      color: white;
      font-size: 15px;
      position: absolute;
      left: 25px;
      z-index: -1;
      text-align: right;
    }

    svg {
      width: 31px;
      height: 21px;
    }
  }

  @media screen and (max-width: 900px) {
    transform: unset;
  }
}

.l-menu.none {
  display: none;
}

.servers-list,
.exclusions-list {
  .l-menu {
    &.backup-details-icon {
      transform: translateX(2px);
    }
  }
}
