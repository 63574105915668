$whiteBackground: #ececec;
$primary: #2c54ac;
$primaryLight: #d7dcef;
$primaryActive: #1e5cb2;
$secondary: #fcac04;
$secondaryLight: #f8b014;
$secondaryActive: #fdb51c;
$hoverButtonText: #2c54ac;
$activeButtonText: #fff;
$notifications: #e49d45;
$notificationsLight: #de9234;
$notificationsActive: #6f4e2b;
$textColor: #555;

.top-panel .profile-menu {
  div {
    width: auto;
    height: auto;
  }

  button {
    cursor: default;
    background: none;
    box-shadow: unset;
    width: 100%;
    height: 100%;

    &:hover {
      background: none;
    }

    &:active, &:focus {
      outline: none;
      border: unset;
      background: unset;
      background-color: unset;
      border-color: unset;
    }
  }

  .btn-danger:not(:disabled):not(.disabled).active,
  .btn-danger:not(:disabled):not(.disabled):active,
  .show>.btn-danger.dropdown-toggle {
    background-color: unset;
    border-color: unset;
    box-shadow: unset;
  }

  .dropdown-menu {
    display: none;
    height: auto;
    max-height: 75vh;
    overflow: auto;
    cursor: default;
    background: #222e44;
    border: 1px solid #fcac04;
    border-width: 1px 0 0 0;
    box-shadow: rgb(34, 46, 68) 0px 0px 10px -1px;

    > div {
      cursor: default;
    }
  }
  
  .dropdown-menu.show {
    display: flex;
    flex-direction: column;
    width: auto;
    width: 340px;
    margin: 0;

    .dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 0;
      padding-right: 5px;

      span.title {
        display: block;
        width: 100%;
        padding: 10px 0 0 10px;
        text-align: left;
        color: $secondary;
      }

      span.delete-notification {
        width: 10px;
        height: 10px;
        background: $secondary;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          border: 2px solid $secondaryLight;
          background: transparent;
        }
      }

      &:hover {
        background: none;
      }
    }

    .dropdown-item + div {
      text-align: left;
      width: 100%;
      padding: 0 10px 20px 10px;
      color: #ABABAB;

      a {
        display: contents;
        color: $notifications;

        &:hover {
          color: $notificationsLight;
        }
      }
    }

    .dropdown-divider {
      margin: 0;
      border-color: #555;
      width: 100%;

      &:last-child {
        display: none;
      }
    }
  }
}