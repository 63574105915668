.main-nav {
  width: 100%;
  height: 249px;

  .nav-wrapper {
    position: fixed;
    width: 100%;
    animation: toggleNav .75s forwards;
    z-index: 1;
  }
}

// Small Devices
@media (max-width: 900px) {
  .main-nav {
    height: 0;
  }

  @keyframes toggleNav {
    from {
      transform: translateY(-150px);
    }
  
    to {
      transform: translateY(50px);
    }
  }
}