@import 'src/utils/scss/variables';

.path {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  padding: 1px 0 0 5px;
  height: 40px;
  background: #222e44;
  color: #999;
  box-shadow: 0 2px 10px -4px #222e44;

  .btn-group {
    width: 75px;
    margin-left: 15px;
    background: none;
    box-shadow: none;

    .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
      background: #222e44;
      border: #222e44;
    }

    button {
      padding-left: 0;
      padding-right: 5px;
      color: #999;
      background: #222e44;
      border: #222e44;
      transition: none;
      
      span {
        color: #999;
        padding: 0 0 0 5px;
      }

      &:active, &:focus {
        background: #222e44;
        border: #222e44;
      }
    }

    .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
      box-shadow: none;
      outline: none;
      background: #222e44;
      border: #222e44;
    }

    .btn-secondary {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .clickable {
    padding-top: 8px;
    cursor: pointer;
  }
}

.active-path {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  padding: 1px 0 0 5px;
  height: 40px;
  background: #d7dcef;
  color: $white;
  box-shadow: 0 2px 6px -2px #d7dcef;

  .clickable-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }

  .btn-group {
    width: 75px;
    background: none;
    box-shadow: none;

    .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
      border-color: #d7dcef;
      background: #d7dcef;
    }

    button {
      padding-left: 0;
      padding-right: 5px;
      color: white;
      border-color: #d7dcef;
      background: #d7dcef;
      transition: none;

      span {
        color: #fff;
        padding: 0 0 0 5px;
      }

      &:active, &:focus {
        border-color: #d7dcef;
        background: #d7dcef;
      }
    }

    .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
      box-shadow: none;
      outline: none;
      border-color: #d7dcef;
      background: #d7dcef;
    }

    .btn-secondary {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary;

      span {
        color: $primary;
      }

      &:hover {
        color: #1c3876;
        border-color: #d7dcef;
        background: #d7dcef;

        span {
          color: #1c3876;
        }
      }
    }
  }

  .clickable {
    color: $primary;
    padding-top: 8px;
    cursor: pointer;
  
    &:hover {
      color: #1c3876;
    }

    &:active {
      color: $primary;
    }
  }
}

.clickable-path {
  display: flex;
  float: 0 0 auto;
}