@import 'src/utils/scss/variables';

.r-menu {
  > div {
    > button {
      font-size: 13px;
      background: white;
      border-color: white;
      color: $textColor;

      &:hover {
        background: white;
        border-color: white;
        color: $textColor;
      }
    }
  }
}

.cron-wrapper {
  .r-col .stats > div {
    flex: none;
    width: 17%;
  }

  .actions {
    > div:nth-child(1) a:hover,
    > div:nth-child(1) button:hover {
      background: $primaryLight;
    }

    > div:nth-child(2) a:hover,
    > div:nth-child(2) button:hover {
      background: #ff3438;
    }
  }
}