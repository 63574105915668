.servers-list .l-col {
  .text-status {
    display: block;
  }

  div.star {
    display: none;
  }

  .servers-wrapper {
    .list-item:first-child {
      margin-top: 0;
    }

    .l-col {
      width: 16.3%;

      .server-name {
        font-size: 28px;
      }
    }
  }
}