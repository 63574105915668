@import 'src/utils/scss/variables';

html {
  overflow-y: scroll;
}

.App {
  font-size: 25px;
  font-family: Arial;
}

.window {
  background: #ececec;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .lists-container {
    display: flex;
    flex-direction: row;
  }
}

.active {
  background: #fff;
}

.progress {
  height: 10px;
  transition: all ease-out 0.1s;
}

.Toastify__toast-body {
  font-size: 20px;
  word-break: break-word;
}
.Toastify__toast-container {
  top: 15em;
}

.actions {
  div {
    a.link-download:hover, button.link-download:hover,
    a.link-edit:hover, button.link-edit:hover {
      background: $primary !important;
    }
    
    a.link-list:hover,
    button.link-list:hover {
      background: $primary !important;
    }

    a.link-delete:hover,
    button.link-delete:hover {
      background: $danger !important;
    }

    a.link-gray:hover,
    button.link-gray:hover {
      background: #afafac !important;
    }

    button {
      text-transform: inherit;
      font-weight: bolder;
      background: #dfdedd;
      border: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .actions {
    background: unset !important;
    flex-wrap: wrap;
    justify-content: flex-end !important;

    div {
      height: 30px !important;

      a,button {
        background: #dfdedd !important;
        padding: 0px 10px !important;
        height: 100% !important;
        font-size: 10px !important;
      }
    }
  }
}

button:active, a:active, .period:active {
  color: $primaryActive;
}

.list-item:first-child {
  margin-top: 5px;
}

.list-item .text-status {
  display: none;
}

button {
  &:hover, &:active, &:focus {
    outline: none;
  }
}

.fixed-buttons.fm {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > div {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 5px;
    border-radius: 50%;
    background: #c3c3c3;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &:hover {
      color: $hoverButtonText;
      background: $primaryLight;
    }
  
    &:active {
      color: $activeButtonText;
      background: $primaryActive;
    }
  
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(1.32);
  
      svg {
        color: white;
        height: 70%;
      }
    }
  }

  > div:first-child {
    background: $primary;

    &:hover {
      color: $hoverButtonText;
      background: $primaryLight;

      button svg {
        color: $hoverButtonText;
      }
    }

    &:active {
      color: $activeButtonText;
      background: $primaryActive;

      button svg {
        color: $activeButtonText;
      }
    }

    button {
      padding: 0;

      svg {
        color: $activeButtonText;
      }
    }
  }
}

@media (min-width: 1067px) {
  .App div.content {
    padding-top: 0 !important;
    margin-top: -20px !important;
  }
}

@media (max-width: 1066px) {
  .App div.content {
    padding-top: 0 !important;
    margin-top: -20px !important;
  }

  .content > div {
    .l-col {
      margin-top: 2rem;
      padding-left: 5px;
    }

    .r-col {
      margin-top: 1rem;
      .name {
        margin-top: 2rem;
      }

      .stats {
        flex-wrap: wrap;

        > div {
          margin: 1rem 0 !important;
          padding: 0 !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .App div.content {
    padding-top: 77px !important;
    margin-top: 0 !important;
  }

  .content > div {
    .l-col {
      padding-left: 5px;
    }

    .r-col {
      padding-left: 2rem;
    }
  }
}

@media (max-width: 725px) {
  .content {
    .r-col {
      padding-left: 3.5rem;
    }
  }
}
